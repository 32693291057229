<template>
  <div class="h-full">
    <SharePageDatAdsHeader v-if="isSharePage" />
    <slot />
    <NotificationWrapper />
  </div>
</template>

<script lang="ts" setup>
useHead({
  htmlAttrs: {
    class: "h-full bg-gray-50",
  },
  bodyAttrs: {
    class: "h-full",
  },
});

const isSharePage = computed(() => {
  return useIsCurrentRoute("/share") || useIsCurrentRoute("/snapshot");
});
</script>
