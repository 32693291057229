<template>
  <nav
    class="flex items-center justify-between p-5 px-8 bg-white shadow"
    aria-label="Global"
  >
    <div class="flex flex-1">
      <a
        href="https://datads.io"
        target="_blank"
        rel="noopener noreferrer"
        class="-m-1.5 p-1.5"
      >
        <span class="sr-only">DatAds</span>
        <img class="h-8 w-auto" src="~/assets/img/logo-wide.png" alt="DatAds" />
      </a>
    </div>
    <div class="flex flex-1 gap-x-2 justify-end">
      <a
        href="https://app.datads.io"
        target="_blank"
        rel="noopener noreferrer"
        class="rounded-md border border-primary-400 px-3.5 py-2.5 text-primary-400 text-sm font-semibold shadow-sm hover:bg-gray-100"
        >Sign In</a
      >
      <a
        :href="signUpUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="rounded-md bg-primary-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-300"
        >Sign Up</a
      >
    </div>
  </nav>
</template>

<script setup lang="ts">
import { SIGN_UP_URL } from "~/shared/constants";
const signUpUrl = SIGN_UP_URL;
</script>
